.App {
  text-align: center;
  width: 100vw;
}

h1 {
  font-family: 'Bigelow Rules';
  font-size: 72px;
  color: #0ccac4;
}
