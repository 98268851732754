$base-height: 80vh;

.awssld {
  --slider-height-percentage: 51%;
  --slider-transition-duration: 200ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #FBC02D;
  --control-button-width: 18%;
  --control-button-height: 61%;
  --control-button-background: transparent;
  --control-bullet-color: #FF8862;
  --control-bullet-active-color: #FE480E;
  --loader-bar-color: #ff5722;
  --loader-bar-height: 5px;
}

.awssld__wrapper {
  height: $base-height;
}

.awssld__container {
  height: $base-height;
  padding: 0;
}

.aws-sld {
  &__content {
    p {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition: transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1), opacity 0.35s ease-out;
    }

    p:nth-child(2) {
      transition-delay: 0.05s, 0.05s;
    }

    &--exit {
      p {
        transition: transform 0.225s cubic-bezier(0.85, 0, 0.85, 0.7), opacity 0.4s ease-out;
      }

      p:nth-child(2) {
        transition-delay: 0.05s, 0.05s;
      }
    }

    &--moveLeft {
      p {
        transform: translate3d(-50px, 0, 0);
        opacity: 0;
      }
    }

    &--moveRight {
      p {
        transform: translate3d(50px, 0, 0);
        opacity: 0;
      }
    }
  }
}